/* General Styling */
.About {
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #f1f5f9;
  font-family: 'Inter', sans-serif;
  text-align: center;
}

.About-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.About-Header h1 {
  font-size: 2.5rem;
  color: #facc15;
  margin-bottom: 1rem;
}

.About-Header p {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #e2e8f0;
  max-width: 800px;
  margin: 0 auto;
}

/* Cards Section */
.About-Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.About-Card {
  flex: 1;
  min-width: 280px;
  background-color: #0f172a;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.About-Card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

.About-Card h2 {
  font-size: 1.5rem;
  color: #facc15;
  margin-bottom: 1rem;
}

.About-Card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #cbd5e1;
}

.About-Card ul {
  list-style: none;
  padding: 0;
}

.About-Card ul li {
  font-size: 1rem;
  line-height: 1.6;
  color: #cbd5e1;
  padding: 0.5rem 0;
}

.Card-Icon {
  font-size: 3rem;
  color: #facc15;
  margin-bottom: 1rem;
}

/* Hover Animation */
.Card-Icon i {
  transition: transform 0.2s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .About-Header h1 {
    font-size: 2rem;
  }

  .About-Header p {
    font-size: 1rem;
  }

  .About-Card h2 {
    font-size: 1.25rem;
  }

  .About-Card p,
  .About-Card ul li {
    font-size: 0.9rem;
  }
}
