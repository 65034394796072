/* General Reset and Body Styling */
body {
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  background-color: #f7f7f7;
  color: #333;
  line-height: 1.6;
}

/* Hero Section */
.loadboard-hero-section {
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1460925895917-afdab827c52f");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.loadboard-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
}

.loadboard-hero-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: bold;
  margin-bottom: 15px;
}

.loadboard-hero-subtitle {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  margin-bottom: 20px;
  max-width: 700px;
  line-height: 1.6;
  text-align: center;
}

/* Buttons */
.auth-buttons {
  display: flex;
  gap: 15px;
}

.btn {
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn.primary {
  background-color: #007bff;
  color: white;
}

.btn.primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn.secondary {
  background-color: white;
  color: #007bff;
  border: 2px solid #007bff;
}

.btn.secondary:hover {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}

/* Entity Title Section */
.loardboard-entity-title {
  text-align: center;
  padding: 40px 20px;
}

.entity-main-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: bold;
  margin-bottom: 10px;
}

.entity-subtitle {
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  color: #555;
  margin-top: 10px;
}

/* Features Section */
.features-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.feature-card {
  position: relative;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.feature-image-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-image {
  width: 100%; /* Ensure the image spans the container width */
  height: 300px; /* Set a consistent maximum height */
  object-fit: cover; /* Ensure the image scales properly without distortion */
  border-radius: 10px; /* Apply a consistent border radius for rounded corners */
  transition: transform 0.3s ease; /* Smooth zoom effect on hover */
}


.feature-card:hover .feature-image {
  transform: scale(1.05);
}

.overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 123, 255, 0.9);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay-button:hover {
  background-color: rgba(0, 86, 179, 0.9);
}

/* Details Section */
.details-section {
  display: flex;
  justify-content: space-around;
  padding: 60px 20px;
  align-items: center;
  flex-wrap: wrap;
}
.details-section-carrier {
  display: flex;
  justify-content: space-around;
  padding: 60px 20px;
  flex-wrap: wrap;
  color: #f7f7f7;
  background-color: #02226d;
}
.authenticated-section h2 {
  padding: 30px;
}
.details-wrapper h3 {

color: cadetblue;
}
.membership-plans-section {
    background-color: #02226d;
}

.testimonials-section {

}
.details-wrapper {
  flex: 1;
  max-width: 500px;
}

.details-wrapper h2 {
  padding: 10px 0;
  font-size: 1.8rem;
  color:cadetblue;
}

.details-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.details-image {
  flex: 1;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  margin-top: 20px;
}

.icon {
  color: #007bff;
  font-size: 1.2rem;
}

/* Membership Plans Section */
.membership-plans-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.membership-plans-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.membership-plan-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.membership-plan-card h3 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.plan-price {
  font-size: 1.2rem;
  color: #555;
  margin: 15px 0;
}

.plan-features {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;
}

.plan-features li {
  margin: 5px 0;
  color: #333;
}

.plan-features li .icon {
  margin-right: 5px;
  color: #007bff;
}

.membership-plan-card .btn {
  width: 100%;
}

/* Testimonials Section */
.testimonials-section {
  background-image: url("https://images.unsplash.com/photo-1725781535657-29d825bc7824?q=80&w=3580&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white; /* Ensure text stands out against the background */
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

/* .testimonials-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
} */

.testimonial-card {
  background: rgba(0, 0, 0, 0.7); /* Add a semi-transparent overlay for better readability */
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white;
}

.testimonial-card p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.testimonial-card h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-section{
    padding: 20px 0px;
  }
    .details-section {
    padding: 30px 20px;

    }
  .feature-card {
    width: 90%;
  }
.feature-image{
  height: 100%;
}
  .details-image {
    margin-top: 20px;
    width: 100%;
  }
   .testimonials-section h2 {
    font-size: 2rem;
  }

  .testimonial-card {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
