.shipment-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.shipment-details:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .shipment-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .shipment-details {
    gap: 10px;
    padding: 8px 0;
  }
}
