/* General Section Styling */
.testimony-section {
  background-color:#f9f9f9d6 ;
  padding: 50px 20px;
  text-align: center;
}

.testimony-header h2 {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 10px;
}

.testimony-header h2 span {
  color: #333;
  font-weight: bold;
}

.testimony-header p {
  color: #555;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

/* Testimony Cards Container */
.testimony-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Individual Testimony Card */
.testimony-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  flex: 1;
  min-width: 280px;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.testimony-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.quote-icon {
  color: #007bff;
  font-size: 1.5rem;
  position: absolute;
  top: 15px;
  left: 15px;
}

.testimony-text {
  font-size: 1rem;
  color: #555;
  margin: 20px 0;
  line-height: 1.6;
  font-style: italic;
}

.testimony-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #007bff;
}

.testimony-author h4 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.testimony-rating .star {
  color: #ffcc00;
  font-size: 1rem;
  margin-right: 3px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimony-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .testimony-card {
    max-width: 100%;
    min-width: auto;
  }
}
