.form-title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  /* border-bottom: 1px solid gray; */
     padding: 6rem 5rem;
 
}

@media screen and (max-width: 414px) {
  .form-title {
    padding: 4rem 1rem;

  }

}