.enhanced-contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #04226d, #eaac0f);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.contact-form-wrapper {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
}

.contact-form-headers {
  text-align: center;
  margin-bottom: 2rem;
}

.contact-form-headers h3 {
  color: #04226d;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.contact-form-headers h1 {
  color: #eaac0f;
  font-weight: 700;
}

.enhanced-main-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.contact-input-group {
  display: flex;
  gap: 1rem;
}

.contact-input-group input {
  flex: 1;
  padding: 0.65rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;
}

.contact-input-group input:focus {
  border-color: #04226d;
  box-shadow: 0px 0px 8px rgba(4, 34, 109, 0.2);
}

.contact-textarea textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  resize: vertical;
  min-height: 120px;
  transition: all 0.3s ease;
}

.contact-textarea textarea:focus {
  border-color: #04226d;
  box-shadow: 0px 0px 8px rgba(4, 34, 109, 0.2);
}

.enhanced-submit-btn {
  background: linear-gradient(135deg, #04226d, #eaac0f);
  color: #ffffff;
  padding: .9rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.enhanced-submit-btn:hover {
  background: linear-gradient(135deg, #eaac0f, #04226d);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .contact-input-group {
    flex-direction: column;
    gap: 0.75rem;
  }

  .enhanced-contact-form {
    padding: 2rem 1rem;
  }

  .contact-form-headers h1 {
    font-size: 2rem;
  }
}
