/* General Container Styling */
.custom-container {
  padding: 20px;

  max-width: 1200px;
}

/* Title Styling */
.custom-title {
  color: #007bff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* No Shipments Text */
.no-shipments-text {
  text-align: center;
  color: #666;
  font-size: 18px;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custom-container {
    padding: 10px;
  }

  .custom-title {
    font-size: 20px;
  }

  .no-shipments-text {
    font-size: 16px;
  }
}
