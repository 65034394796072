/* Footer Styles */
.footer {
background: linear-gradient(135deg, #1e293b, #0f172a); 
color: #ffffff;
  padding: 40px 20px 20px;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer-logo {
  flex: 1 1 100%;
  text-align: center;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 10px;
}

.slogan {
  font-size: 1.2em;
  margin: 5px 0;
  color: #f0c14b;
}

.contact-info {
  font-size: 0.9em;
  color: #d1d1d1;
}

.footer-links {
  flex: 1 1 45%;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.link-section {
  flex: 1;
}

.link-section h4 {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #f0c14b;
}

.link-section ul {
  list-style: none;
  padding: 0;
}

.link-section a {
  color: #d1d1d1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-section a:hover {
  color: #f0c14b;
}

.newsletter {
  flex: 1 1 45%;
  text-align: left;
}

.newsletter h4 {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #f0c14b;
}

.newsletter p {
  font-size: 0.9em;
  color: #d1d1d1;
  margin-bottom: 10px;
}

.newsletter form {
  display: flex;
  flex-direction: column;
}

.newsletter input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 1em;
}

.submit-btn {
  padding: 10px;
  border: none;
  background-color: #f0c14b;
  color: #1c1c1e;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #d1a345;
}

.social-icons {
  flex: 1 1 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  color: #f0c14b;
  font-size: 1.5em;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #d1a345;
}

.footer-bottom {
background-color: #0f172a;
  padding: 10px;
  font-size: 0.85em;
  color: #d1d1d1;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .link-section {
    text-align: center;
  }
}
