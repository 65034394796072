.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 100px 20px;
}

.service-item {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: #f4f7fc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item svg {
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.service-item:hover svg {
  transform: scale(1.1);
}

.service-item h2 {
  font-size: 1.3rem;
  color: #04226d;
  margin: 10px 0;
  font-weight: 600;
}

.service-item p {
  font-size: 1rem;
  color: #666;
  margin: 10px 0;
}

@media (min-width: 768px) {
  .service-item {
    flex: 0 1 calc(50% - 40px);
  }
}

@media (min-width: 1024px) {
  .service-item {
    flex: 0 1 calc(33.333% - 40px);
  }
}
