/* General styling */
h3 {
  text-transform: capitalize;
  font-weight: 600;
  color: #333;
}

/* Home container styling */
.home-content {

  animation: fadeIn 1.5s ease-in-out;
}

/* Sections spacing and animation */
.home-content > section {
  /* padding-bottom: 30px; */
  border-radius: 8px;
  background: #fff;
  animation: fadeInUp 1s ease-in-out;
}

/* Keyframes for fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design for mobile */
@media screen and (max-width: 768px) {
  .home-content {
  }

  .home-content > section {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .home-content > section {
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.1rem;
  }
}
