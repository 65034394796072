.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #04226d, #eaac0f);
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

#contact-form {
  flex: 1;
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.form-header h2 {
  font-size: 1.5rem;
  color: #04226d;
}

.form-header p {
  font-size: 1rem;
  color: #555;
}

.form-group {
  margin-bottom: 1rem;
}

.form-input,
.form-textarea {
      width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    background-color: aliceblue;
}

.form-input:focus,
.form-textarea:focus {
  border-color: #04226d;
  box-shadow: 0px 0px 6px rgba(4, 34, 109, 0.2);
}

.form-textarea {
  resize: none;
  height: 120px;
}

.submit-btn {
  background-color: #eaac0f;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #04226d;
}

.contact-img img {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
}

/* Responsiveness */
@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
    gap: 1.5rem;
  }

  .contact-img {
    display: none;
  }
}
