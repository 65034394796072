.hero-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background: #000;
}

.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.hero-slide.active {
  opacity: 1;
  visibility: visible;
}

.hero-video,
.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
background: rgb(146 135 135 / 60%);  
padding: 2rem;
  border-radius: 12px;
  max-width: 700px;
  width: 90%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.hero-header {
  font-size: 2.5rem;
  margin: 1rem 0;
  font-weight: bold;
  line-height: 1.2;
}

.hero-subheader {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.hero-paragraph {
  font-size: 1.125rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hero-header {
    font-size: 1.75rem;
  }

  .hero-subheader {
    font-size: 1.25rem;
  }

  .hero-paragraph {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-header {
    font-size: 1.5rem;
  }

  .hero-subheader {
    font-size: 1rem;
  }

  .hero-paragraph {
    font-size: 0.875rem;
  }
}
