/* General Styling */
.about-container {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  font-family: "Inter", sans-serif;
}

.about-content-section {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.about-content {
  padding: 2rem;
  border-radius: 12px;
  text-align: left;
}

.about-content h2 {
  font-size: 2rem;
  color: #1a202c;
  margin-bottom: 1rem;
}

.about-content p {
  font-size: 1.125rem;
  color: #4a5568;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.about-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.about-content ul li {
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.about-video-container {
  width: 50%;
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.react-player {
  border-radius: 12px;
  height: 100%;
  width: 100%;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
  .about-content-section {
    display: grid;
  }

  .about-content {
    padding: 0px;
  }

  .about-content h2 {
    font-size: 1.75rem;
  }

  .about-content p,
  .about-content ul li {
    font-size: 0.95rem;
  }

  .about-video-container {
    height: 300px; 
    width: 100%;
  }
}
