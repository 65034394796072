.dashboard-content {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.dashboard-buttons {
  display: flex;

}
@media screen and (max-width: 768px) {
  .dashboard-content {
    padding: 10px;
  }
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
  color: white;
}
