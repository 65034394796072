.home-services {
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 3rem 1rem;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.service-section {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: flex-end;
      padding: 30px 0px;
}

.service-section-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.home-headings h3 {
  color: #555;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.home-headings h1 {
  margin: 0;
  color: #02226D;
}

.home-service-description {
  margin: 1rem 0;
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

/* Service Item */
.service-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);  /* Static box shadow */
  transition: transform 0.3s ease;
}

.icon-circle {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8eef9;
  border-radius: 50%;
  align-items: flex-end;
}

.service-details h2 {
  font-size: 1.25rem;
  margin: 0;
  color: #02226D;
}

.service-details p {
  margin: 0;
  font-size: 0.95rem;
  color: #555;
}

/* Right Section */
.service-section-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-truck-img {
  width: 334px;
  transition: transform 0.3s ease;
}

.mini-truck-img {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 80px;
  opacity: 0.9;
  transition: transform 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-headings h1 {
    font-size: 2rem;
  }

  .service-list {
    grid-template-columns: 1fr;
  }

  .service-truck-img {
    width: 250px;
  }

  .mini-truck-img {
    width: 60px;
    right: 5%;
  }
}

@media (max-width: 480px) {
  .service-section {
    flex-direction: column;
    align-items: center;
  }

  .service-section-right {
    margin-top: 2rem;
  }

  .service-truck-img {
    width: 200px;
  }

  .mini-truck-img {
    width: 50px;
    bottom: -10px;
    right: 0;
  }
}
