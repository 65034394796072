.googmap {
  width: 100%;
  height: 400px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.map-container {
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
