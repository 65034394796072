/* Main header styles */
.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 270px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: #04226d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #eaac0f;
}

/* Active link styling */
.active-link {
  color: #eaac0f;
  border-bottom: 2px solid #eaac0f;
  padding-bottom: 2px;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  color: #04226d;
}

/* Styles for mobile navigation */
@media screen and (max-width: 820px) {
  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background-color: #04226d;
    padding-top: 5rem;
    align-items: center;
    gap: 1.5rem;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.open a {
    color: #ffffff;
    font-size: 1.1rem;
  }

  .nav-links.open a:hover {
    color: #eaac0f;
  }
}

.nav-links-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links-list li {
  margin: 0;
}

.nav-links-list a,
.nav-links-list span {
  color: #04226d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-links-list a:hover,
.nav-links-list span:hover {
  color: #eaac0f;
}

@media screen and (max-width: 820px) {
  .nav-links-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }
}
