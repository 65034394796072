/* Clients Section Styling */
.homeClient {
  padding: 5rem 1rem;
  background: linear-gradient(135deg, #f1f5f9, #e2e8f0);
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.homeContainer{
  max-width: 1200px;
  margin: 0 auto;
}

.clients-header h3 {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.clients-header h1 {
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 2rem;
}

/* Clients Grid */
.clients-logo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Client Logo Styling */
.client-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-logo-img:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.client-logo-img img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Responsive Design */
@media (max-width: 768px) {
  .clients-header h1 {
    font-size: 2rem;
  }

  .clients-header h3 {
    font-size: 1.25rem;
  }

  .clients-logo-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .clients-logo-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .clients-header h1 {
    font-size: 1.75rem;
  }

  .clients-header h3 {
    font-size: 1rem;
  }
}
