.header {
  height: 240px;
  display: flex;
  justify-content: center;
}

.header h2 {
  text-align: center;
  font-size: 27px;
  color: white;
  align-items: center;
  letter-spacing: 5px;
  font-weight: 500;
  text-transform: uppercase;
  margin: auto;
}

.bgImage {
  background-image: url("../components//Images/contactback.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

