* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  letter-spacing: 0.5px;
}

body {
  font-family: 'Saira Condensed',
    sans-serif;
}

.container {
  padding: 0px;
}

p {
  font-family: 'Saira Condensed',
    sans-serif;
  font-size: 18px;
  margin-bottom: 0;
  /* width: 100%; */
}

h1, h2 {
  color: #02226D;
  margin-bottom: 0;
}


h3 {
  font-size: 1.7rem;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'Saira Condensed',
    sans-serif;
    padding: 10px;
}

@media only screen and (min-width: 320px) {
  p{
    font-size: 14px;
    opacity: 70%;
  }
  h1{
    font-size: 1.1em;
    padding: 0px;
    margin: 0px;
    
  }
  h2 {

  
  }
  h3{
    font-size: .9rem;
    padding: 0px;
    margin: 0px;
  }
}
@media only screen and (min-width: 992px) {
  p{
    font-size: 17px;
  }
  h1{
    font-size: 27px;
  }
  h2 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  h3{
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1200px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}